body{
  font-family: 'Open Sans', sans-serif;
}


// Utilities
.brand-color-primary {
  color: $brand-color-primary;
}

.brand-color-secondary {
  color: $brand-color-secondary;
}

.brand-bg-primary {
  background-color: $brand-color-primary;
}

.brand-bg-secondary {
  background-color: $brand-color-secondary;
}
.brand-bg-secondary:hover {
  background-color: $brand-color-secondary;
}

.text-small {
  font-size: 0.7rem;
}

// Navbar
.nav-link {
  font-size: $font-size-sm;
}

// Icons
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

// Buttons
.btn-signup {
  --bs-btn-border-radius: 1.5rem;
  &:hover {
    background-color: $brand-color-secondary;
  }
}

// Hero
.hero {
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.hero-mtns {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/img/security_office.jpg');
}

.hero-store {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/img/store.jpg');
}

.hero-partner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/img/partnerbg.png');
}

.hero-about {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/img/about_hero.png');
}

.hero-pricing {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/img/pricing_hero.png');
}

.hero-case {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/img/case_hero.png');
}

// Blog
.blog-featured-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}


// CTA
.cta{
  margin-bottom: 20px;
}
.cta_bg_dark{
  color: #fff;
  background-color: $brand-color-primary;
}
.cta_bg_1{
  background-image: url('/assets/img/cta_bg_1.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
}


//Panel
.panel{
  padding: 40px;
}

.panel_bg-light-lt-grey{
  background-color: #F5F5F5;
}

.panel_bg-white{
  background-color: #FFF;
}

.panel_bg-light{
  background-color: #EBEBEB;
}

.panel_bg-dk-grey{
  background-color: #CBCBCB;
}

.panel_bg-dark{
  background-color: #13202E;
  color: #EBEBEB;
}


.partner-img{
  min-height: 200px;
}
