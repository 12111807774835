// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/functions";

// 2. Include any default variable overrides here

// custom variables
$brand-color-primary: #13202e;
$brand-color-secondary: #e62332;

// 3. Include remainder of required Bootstrap stylesheets
@import "bootstrap/variables";

// 4. Include any default map overrides here
// bootstrap variables
$navbar-dark-active-color: $brand-color-secondary;
$navbar-dark-color: rgba($white, .80);
$navbar-dark-hover-color: rgba($brand-color-secondary, .80);

// 5. Include remainder of required parts
@import "bootstrap/maps";
@import "bootstrap/mixins";
@import "bootstrap/root";

// 6. Include any optional Bootstrap CSS as needed
@import "bootstrap/utilities";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/grid";
@import "bootstrap/helpers";
@import "bootstrap/containers";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/pagination";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/utilities/api";

// 8. Additional custom code
@import "base";